import React, { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button, Col, Flex, Form, Image, Input, Row, Select, Space, Spin } from 'antd';

import { initFormik } from './formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';

import { ClientModel } from '../../reducers/clients/model';
import { addClientRequest, getClientsRequest } from '../../reducers/clients/actions';

import { ClientForm, ClientFormValues } from '../client';
import { DrawerAddEdit } from '../../component/drawerAditEdit/DrawerAddEdit';
import { ProductInReservation, ReservationModel } from '../../reducers/reservations/model';
import { useGetAvailableForklifts } from '../../services/apiClient/forklifts/forkliftQueries';

import './style.scss';

const { Item } = Form;
const { TextArea } = Input;

export type ReservationFormValues = {
  reservationNr: string;
  clientId?: string;
  productsInReservation: ProductInReservation[];
  notes?: string;
};

export type ReservationFormProps = {
  reservation: Partial<ReservationModel>;
  handleSubmit: (values: ReservationFormValues) => void;
  handleCancelForm: () => void;
};

export const ReservationForm: FC<ReservationFormProps> = props => {
  const { handleCancelForm } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.reservations.isFetching);
  const clientsList = useSelector<RootReducerState, ClientModel[]>(state => state.clients.clientsList);
  const { data: forkliftList = [], isFetching: isFetchingAvailableForklifts } = useGetAvailableForklifts();
  const [openClientModal, setOpenClientModal] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik(initFormik(props));

  useEffect(() => {
    dispatch(getClientsRequest());
  }, [dispatch]);

  const productsInReservation = formik.values.productsInReservation.map(product =>
    forkliftList.find(forklift => forklift._id === product.productId),
  );

  const handleSubmitClient = (values: ClientFormValues) => {
    dispatch(
      addClientRequest(values, client => {
        setOpenClientModal(false);
        void formik.setFieldValue('clientId', client?._id);
      }),
    );
  };

  return (
    <div className='wrapperForm'>
      <Form layout='vertical'>
        <Row
          justify='space-between'
          align='middle'
        >
          <Col span={19}>
            <Item
              label='Klient'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.clientId && formik.touched.clientId ? 'error' : ''}
              help={formik.errors.clientId && formik.touched.clientId ? formik.errors.clientId : null}
            >
              <Select
                showSearch
                value={formik.values.clientId}
                onChange={value => formik.setFieldValue('clientId', value)}
                onBlur={() => formik.setFieldTouched('clientId')}
                options={clientsList.map(client => ({
                  label: client.companyName,
                  value: client._id,
                }))}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Item>
          </Col>
          <Col
            span={4}
            className='right'
          >
            <Button onClick={() => setOpenClientModal(true)}>Dodaj klienta</Button>
          </Col>
        </Row>
        <Item
          label='Dane klienta'
          hasFeedback={true}
          validateStatus={formik.errors.notes && formik.touched.notes ? 'error' : ''}
          help={formik.errors.notes && formik.touched.notes ? formik.errors.notes : null}
        >
          <TextArea
            value={formik.values.notes}
            name='notes'
            showCount
            maxLength={1000}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder='Dane klienta'
          />
        </Item>
        {isFetchingAvailableForklifts ? (
          <Flex
            align='center'
            justify='center'
          >
            <Spin />
          </Flex>
        ) : (
          productsInReservation.map(product => (
            <Row
              key={product?._id}
              className='rowSpace'
            >
              <Col span={4}>
                {product?.assets && product.assets.length > 0 ? (
                  <Image
                    width={88}
                    src={`${process.env.REACT_APP_HOST_ASSETS_URL ?? ''}${product.assets[0].uri}`}
                  />
                ) : (
                  <Image
                    preview={false}
                    src='../images/placeholder.png'
                    width={80}
                  />
                )}
              </Col>
              <Col span={12}>
                <div>
                  {product?.internalId} - {product?.brand?.name} - {product?.serialNumber}
                </div>
              </Col>
            </Row>
          ))
        )}
        <Row justify='space-between'>
          <Space>
            <Button onClick={handleCancelForm}>Anuluj</Button>
          </Space>
          <Space>
            <Button
              type='primary'
              htmlType='submit'
              loading={isFetching}
              onClick={formik.submitForm}
            >
              Zapisz
            </Button>
          </Space>
        </Row>
      </Form>
      <DrawerAddEdit
        titleEdit='Edytuj dane klienta'
        titleAdd='Dodaj klienta'
        openModal={openClientModal}
        handleCloseModal={() => setOpenClientModal(false)}
      >
        <ClientForm
          handleSubmit={handleSubmitClient}
          handleCancelForm={() => setOpenClientModal(false)}
        />
      </DrawerAddEdit>
    </div>
  );
};
