import React, { FC, useMemo, useState } from 'react';
import { ProductType } from '../../../reducers/orders/model';
import { Button, Dropdown, Flex, Row, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { itemsStatuses } from '../../../utils/itemsStatus';
import { OrderEquipment, OrdersEquipmentsList } from './equipments/OrdersEquipmentsList';
import { OrderForklift, OrdersForkliftsList } from './forklifts/OrdersForkliftsList';
import { OrderParts, OrdersPartsList } from './parts/OrdersPartsList';
import { useGetOrder } from '../../../services/apiClient/orders/orderQueries';

type OrdersProductsListProps = {
  selectedOrder: string;
};

export const OrdersProductsList: FC<OrdersProductsListProps> = props => {
  const { selectedOrder } = props;
  const [groupEditForkliftsStatus, setGroupEditForkliftsStatus] = useState<string[]>([]);
  const [groupEditEquipmentsStatus, setGroupEditEquipmentsStatus] = useState<string[]>([]);
  const [groupEditPartsStatus, setGroupEditPartsStatus] = useState<string[]>([]);
  const dispatch = useDispatch();
  const { data: order, isLoading: isLoading } = useGetOrder(selectedOrder);

  const productInOrder = order?.productsInOrder.map(productId => productId.productId);
  const isEditDropdownEnabled =
    groupEditForkliftsStatus.length > 0 || groupEditEquipmentsStatus.length > 0 || groupEditPartsStatus.length > 0;

  const { orderForklifts, orderEquipments, orderParts } = useMemo(() => {
    const products = order?.productsInOrder.map(product => {
      const moreProductData = order.products?.find(element => element._id === product.productId);

      return {
        ...moreProductData,
        productType: product.type,
      };
    });

    const forklifts = (products ?? []).filter(
      product => product.productType === ProductType.FORKLIFT,
    ) as OrderForklift[];

    const equipments = (products ?? []).filter(
      product => product.productType === ProductType.EQUIPMENT,
    ) as OrderEquipment[];
    const parts = (products ?? []).filter(product => product.productType === ProductType.PART) as OrderParts[];

    return {
      orderForklifts: forklifts,
      orderEquipments: equipments,
      orderParts: parts,
    };
  }, [order]);

  return (
    <>
      <Row justify='end'>
        <Dropdown
          menu={{
            items: itemsStatuses({
              dispatch,
              forkliftIds: groupEditForkliftsStatus,
              equipmentIds: groupEditEquipmentsStatus,
              partIds: groupEditPartsStatus,
            }),
          }}
          arrow
          disabled={!isEditDropdownEnabled}
        >
          <Button
            className='marginLeftButton'
            disabled={!isEditDropdownEnabled}
          >
            Edytuj
          </Button>
        </Dropdown>
      </Row>
      <Flex
        align='center'
        justify='center'
      >
        <Spin spinning={isLoading} />
      </Flex>
      {productInOrder && (
        <>
          <OrdersForkliftsList
            forkliftsList={orderForklifts}
            groupEditForkliftsStatus={groupEditForkliftsStatus}
            onSelection={newSelectedRowKeys => setGroupEditForkliftsStatus(newSelectedRowKeys as string[])}
          />

          <OrdersEquipmentsList
            equipmentList={orderEquipments}
            groupEditEquipmentsStatus={groupEditEquipmentsStatus}
            onSelection={newSelectedRowKeys => setGroupEditEquipmentsStatus(newSelectedRowKeys as string[])}
          />
          <OrdersPartsList
            partsList={orderParts}
            groupEditEquipmentsStatus={groupEditPartsStatus}
            onSelection={newSelectedRowKeys => setGroupEditPartsStatus(newSelectedRowKeys as string[])}
          />
        </>
      )}
    </>
  );
};
