import { Dispatch } from 'react';
import { ProductStatus, ProductStatusNames } from '../reducers/forklift/model';
import { updateForkliftsStatusesRequest } from '../reducers/forklift/actions';
import { updateEquipmentsStatusesRequest } from '../reducers/equipment/actions';
import { updatePartsStatusesRequest } from '../reducers/parts/actions';

export type ItemsStatusesProps = {
  dispatch: Dispatch<any>;
  forkliftIds?: string[];
  equipmentIds?: string[];
  partIds?: string[];
  onSuccess?: () => void;
};

export type ItemStatus = {
  label: string;
  key: ProductStatus | string;
  onClick: () => void;
  disabled?: boolean;
};

export const itemsStatuses = ({
  dispatch,
  forkliftIds,
  equipmentIds,
  partIds,
  onSuccess,
}: ItemsStatusesProps): ItemStatus[] => {
  return Object.values(ProductStatus).map(status => ({
    label: ProductStatusNames[status],
    key: status,
    onClick: () => {
      if (forkliftIds?.length) {
        dispatch(
          updateForkliftsStatusesRequest(
            {
              ids: forkliftIds,
              status: status,
            },
            () => {
              if (typeof onSuccess === 'function') {
                onSuccess();
              }
            },
          ),
        );
      }
      if (equipmentIds?.length) {
        dispatch(
          updateEquipmentsStatusesRequest(
            {
              ids: equipmentIds,
              status: status,
            },
            () => {
              if (typeof onSuccess === 'function') {
                onSuccess();
              }
            },
          ),
        );
      }
      if (partIds?.length) {
        dispatch(
          updatePartsStatusesRequest(
            {
              ids: partIds,
              status: status,
            },
            () => {
              if (typeof onSuccess === 'function') {
                onSuccess();
              }
            },
          ),
        );
      }
    },
  }));
};
