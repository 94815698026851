import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { notification } from 'antd';

import {
  addForkliftError,
  addForkliftRequest,
  addForkliftSuccess,
  deleteForkliftError,
  deleteForkliftRequest,
  deleteForkliftSuccess,
  getAvailableForkliftsError,
  getAvailableForkliftsSuccess,
  getForkliftError,
  getForkliftInternalIdError,
  getForkliftInternalIdSuccess,
  getForkliftRequest,
  getForkliftsError,
  getForkliftsRequest,
  getForkliftsStockistError,
  getForkliftsStockistRequest,
  getForkliftsStockistSuccess,
  getForkliftsSuccess,
  getForkliftSuccess,
  updateForkliftError,
  updateForkliftRequest,
  updateForkliftsStatusesError,
  updateForkliftsStatusesRequest,
  updateForkliftsStatusesSuccess,
  updateForkliftSuccess,
} from './actions';

import * as CONST from './consts';
import { ForkliftInternalId, ForkliftModel } from './model';
import { ResponseModel, ResponseModelMeta } from '../model';
import { getErrorMessage } from '../../utils/error';
import { ActionType } from 'typesafe-actions';
import { apiClientForklifts } from '../../services/apiClient/forklifts';
import { getOrdersRequest } from '../orders/actions';

function* getForklifts(
  action: ActionType<typeof getForkliftsRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModelMeta<ForkliftModel[]>> {
  try {
    const response = yield call(apiClientForklifts.getForklifts, action.payload.query);

    yield put(getForkliftsSuccess({ forklifts: response.data, meta: response.meta }));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get forklifts',
      description: getErrorMessage(err),
    });
    yield put(getForkliftsError(err));
  }
}

function* getAvailableForklifts(): Generator<CallEffect | PutEffect, void, ResponseModel<ForkliftModel[]>> {
  try {
    const response = yield call(apiClientForklifts.getAvailableForklifts);

    yield put(getAvailableForkliftsSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get forklifts',
      description: getErrorMessage(err),
    });
    yield put(getAvailableForkliftsError(err));
  }
}

function* addForklift(
  action: ActionType<typeof addForkliftRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<ForkliftModel>> {
  try {
    const { forklift, onSuccess } = action.payload;
    const response = yield call(apiClientForklifts.addForklift, forklift);

    yield put(addForkliftSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding forklift',
      description: getErrorMessage(err),
    });
    yield put(addForkliftError(err));
  }
}

function* updateForklift(
  action: ActionType<typeof updateForkliftRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<ForkliftModel>> {
  try {
    const { forklift, onSuccess } = action.payload;
    const response = yield call(apiClientForklifts.updateForklift, forklift);

    yield put(updateForkliftSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating forklift',
      description: getErrorMessage(err),
    });
    yield put(updateForkliftError(err));
  }
}

function* deleteForklift(
  action: ActionType<typeof deleteForkliftRequest>,
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { forkliftId, onSuccess } = action.payload;
    yield call(apiClientForklifts.deleteForklift, forkliftId);

    yield put(deleteForkliftSuccess(forkliftId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting forklift',
      description: getErrorMessage(err),
    });
    yield put(deleteForkliftError(err));
  }
}

function* updateForkliftStatuses(
  action: ActionType<typeof updateForkliftsStatusesRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<ForkliftModel[]>> {
  try {
    const { forkliftsStatuses, onSuccess } = action.payload;
    const response = yield call(apiClientForklifts.updateForkliftStatus, forkliftsStatuses);

    yield put(updateForkliftsStatusesSuccess(response.data));
    yield put(getOrdersRequest());
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating forklifts statuses',
      description: getErrorMessage(err),
    });
    yield put(updateForkliftsStatusesError(err));
  }
}

function* getForklift(
  action: ActionType<typeof getForkliftRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<ForkliftModel>> {
  try {
    const { forkliftId } = action.payload;
    const response = yield call(apiClientForklifts.getForklift, forkliftId);

    yield put(getForkliftSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get forklift',
      description: getErrorMessage(err),
    });
    yield put(getForkliftError(err));
  }
}

function* getForkliftId(): Generator<CallEffect | PutEffect, void, ResponseModel<ForkliftInternalId>> {
  try {
    const response = yield call(apiClientForklifts.getForkliftInternalId);

    yield put(getForkliftInternalIdSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get forklift Id',
      description: getErrorMessage(err),
    });
    yield put(getForkliftInternalIdError(err));
  }
}

function* getForkliftsStockList(
  action: ActionType<typeof getForkliftsStockistRequest>,
): Generator<CallEffect | PutEffect, void, ArrayBuffer> {
  try {
    const { onSuccess } = action.payload;
    const response = yield call(apiClientForklifts.getStockList);
    const blobUrl = URL.createObjectURL(
      new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
    );
    window.open(blobUrl, '_blank');
    yield put(getForkliftsStockistSuccess());
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during generating stock list',
      description: getErrorMessage(err),
    });
    yield put(getForkliftsStockistError(err));
  }
}

export function* watchForkliftsSaga(): Generator {
  yield takeLatest(CONST.GET_FORKLIFTS_REQUEST, getForklifts);
  yield takeLatest(CONST.GET_AVAILABLE_FORKLIFTS_REQUEST, getAvailableForklifts);
  yield takeLatest(CONST.ADD_FORKLIFT_REQUEST, addForklift);
  yield takeLatest(CONST.UPDATE_FORKLIFT_REQUEST, updateForklift);
  yield takeLatest(CONST.DELETE_FORKLIFT_REQUEST, deleteForklift);
  yield takeLatest(CONST.UPDATE_FORKLIFTS_STATUSES_REQUEST, updateForkliftStatuses);
  yield takeLatest(CONST.GET_FORKLIFT_REQUEST, getForklift);
  yield takeLatest(CONST.GET_FORKLIFT_INTERNAL_ID_REQUEST, getForkliftId);
  yield takeLatest(CONST.GET_FORKLIFTS_STOCK_LIST_REQUEST, getForkliftsStockList);
}
