import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { ForkliftInternalId, ForkliftMeta, ForkliftModel, ForkliftStatusesModel } from './model';

export const getForkliftsRequest = (query?: string) => action(CONST.GET_FORKLIFTS_REQUEST, { query });
export const getForkliftsSuccess = (data: { forklifts: ForkliftModel[]; meta: ForkliftMeta }) =>
  action(CONST.GET_FORKLIFTS_SUCCESS, { forklifts: data.forklifts, meta: data.meta });
export const getForkliftsError = (error: unknown) => action(CONST.GET_FORKLIFTS_ERROR, { error });

export const getAvailableForkliftsRequest = () => action(CONST.GET_AVAILABLE_FORKLIFTS_REQUEST);
export const getAvailableForkliftsSuccess = (forklifts: ForkliftModel[]) =>
  action(CONST.GET_AVAILABLE_FORKLIFTS_SUCCESS, { forklifts });
export const getAvailableForkliftsError = (error: unknown) => action(CONST.GET_AVAILABLE_FORKLIFTS_ERROR, { error });

export const addForkliftRequest = (
  forklift: Omit<ForkliftModel, '_id' | 'createdAt' | 'deleted' | 'updatedAt' | 'order' | 'costs'>,
  onSuccess?: () => void,
) => action(CONST.ADD_FORKLIFT_REQUEST, { forklift, onSuccess });
export const addForkliftSuccess = (forklift: ForkliftModel) => action(CONST.ADD_FORKLIFT_SUCCESS, { forklift });
export const addForkliftError = (error: unknown) => action(CONST.ADD_FORKLIFT_ERROR, { error });

export const updateForkliftRequest = (forklift: ForkliftModel, onSuccess?: () => void) =>
  action(CONST.UPDATE_FORKLIFT_REQUEST, { forklift, onSuccess });
export const updateForkliftSuccess = (forklift: ForkliftModel) => action(CONST.UPDATE_FORKLIFT_SUCCESS, { forklift });
export const updateForkliftError = (error: unknown) => action(CONST.UPDATE_FORKLIFT_ERROR, { error });

export const deleteForkliftRequest = (forkliftId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_FORKLIFT_REQUEST, { forkliftId, onSuccess });
export const deleteForkliftSuccess = (forkliftId: string) => action(CONST.DELETE_FORKLIFT_SUCCESS, { forkliftId });
export const deleteForkliftError = (error: unknown) => action(CONST.DELETE_FORKLIFT_ERROR, { error });

export const updateForkliftsStatusesRequest = (forkliftsStatuses: ForkliftStatusesModel, onSuccess?: () => void) =>
  action(CONST.UPDATE_FORKLIFTS_STATUSES_REQUEST, { forkliftsStatuses, onSuccess });
export const updateForkliftsStatusesSuccess = (forklifts: ForkliftModel[]) =>
  action(CONST.UPDATE_FORKLIFTS_STATUSES_SUCCESS, { forklifts });
export const updateForkliftsStatusesError = (error: unknown) =>
  action(CONST.UPDATE_FORKLIFTS_STATUSES_ERROR, { error });

export const getForkliftRequest = (forkliftId: string) => action(CONST.GET_FORKLIFT_REQUEST, { forkliftId });
export const getForkliftSuccess = (forklift: ForkliftModel) => action(CONST.GET_FORKLIFT_SUCCESS, { forklift });
export const getForkliftError = (error: unknown) => action(CONST.GET_FORKLIFT_ERROR, { error });

export const getForkliftInternalIdRequest = () => action(CONST.GET_FORKLIFT_INTERNAL_ID_REQUEST);
export const getForkliftInternalIdSuccess = (forkliftInternalId: ForkliftInternalId) =>
  action(CONST.GET_FORKLIFT_INTERNAL_ID_SUCCESS, { forkliftInternalId: forkliftInternalId });
export const getForkliftInternalIdError = (error: unknown) => action(CONST.GET_FORKLIFT_INTERNAL_ID_ERROR, { error });

export const getForkliftsStockistRequest = (onSuccess?: () => void) =>
  action(CONST.GET_FORKLIFTS_STOCK_LIST_REQUEST, { onSuccess });

export const getForkliftsStockistSuccess = () => action(CONST.GET_FORKLIFTS_STOCK_LIST_SUCCESS);

export const getForkliftsStockistError = (error: unknown) => action(CONST.GET_FORKLIFTS_STOCK_LIST_ERROR, { error });
