import styled from 'styled-components';
import { List, Typography } from 'antd';

export const ListItemSC = styled(List.Item)`
  display: flex;
`;

export const ListItemMetaSC = styled(List.Item.Meta)`
  div {
    display: flex;
    justify-content: space-between;
  }
`;

export const TextSC = styled(Typography.Text)`
  &.black {
    color: black;
    margin-bottom: 10px;
    font-weight: bold;
  }
`;
