import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import { ForkliftInternalId, ForkliftMeta, ForkliftModel } from './model';

export type ForkliftsAction = ActionType<typeof actions>;
export type ForkliftsState = {
  readonly isFetching: boolean;
  readonly isFetchingStockList: boolean;
  readonly forkliftsList: ForkliftModel[];
  readonly forkliftsMeta: ForkliftMeta | null;
  readonly forkliftInternalId: ForkliftInternalId | null;
};

export const forkliftsReducer = combineReducers<ForkliftsState, ForkliftsAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_FORKLIFTS_REQUEST:
      case CONST.GET_AVAILABLE_FORKLIFTS_REQUEST:
      case CONST.ADD_FORKLIFT_REQUEST:
      case CONST.UPDATE_FORKLIFT_REQUEST:
      case CONST.DELETE_FORKLIFT_REQUEST:
      case CONST.UPDATE_FORKLIFTS_STATUSES_REQUEST:
      case CONST.GET_FORKLIFT_REQUEST:
      case CONST.GET_FORKLIFT_INTERNAL_ID_REQUEST:
        return true;

      case CONST.GET_FORKLIFTS_SUCCESS:
      case CONST.GET_FORKLIFTS_ERROR:
      case CONST.GET_AVAILABLE_FORKLIFTS_SUCCESS:
      case CONST.GET_AVAILABLE_FORKLIFTS_ERROR:
      case CONST.ADD_FORKLIFT_SUCCESS:
      case CONST.ADD_FORKLIFT_ERROR:
      case CONST.UPDATE_FORKLIFT_SUCCESS:
      case CONST.UPDATE_FORKLIFT_ERROR:
      case CONST.DELETE_FORKLIFT_SUCCESS:
      case CONST.DELETE_FORKLIFT_ERROR:
      case CONST.UPDATE_FORKLIFTS_STATUSES_SUCCESS:
      case CONST.UPDATE_FORKLIFTS_STATUSES_ERROR:
      case CONST.GET_FORKLIFT_SUCCESS:
      case CONST.GET_FORKLIFT_ERROR:
      case CONST.GET_FORKLIFT_INTERNAL_ID_SUCCESS:
      case CONST.GET_FORKLIFT_INTERNAL_ID_ERROR:
        return false;

      default:
        return state;
    }
  },
  isFetchingStockList: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_FORKLIFTS_STOCK_LIST_REQUEST:
        return true;
      case CONST.GET_FORKLIFTS_STOCK_LIST_SUCCESS:
      case CONST.GET_FORKLIFTS_STOCK_LIST_ERROR:
        return false;
      default:
        return state;
    }
  },
  forkliftsList: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_FORKLIFTS_SUCCESS:
      case CONST.GET_AVAILABLE_FORKLIFTS_SUCCESS:
        return action.payload.forklifts;

      case CONST.ADD_FORKLIFT_SUCCESS:
        return [action.payload.forklift, ...state];

      case CONST.GET_FORKLIFT_SUCCESS:
      case CONST.UPDATE_FORKLIFT_SUCCESS: {
        const forkliftIdx = state.findIndex(item => item._id === action.payload.forklift._id);
        state.splice(forkliftIdx, 1, action.payload.forklift);

        return [...state];
      }

      case CONST.UPDATE_FORKLIFTS_STATUSES_SUCCESS: {
        action.payload.forklifts.forEach(forklift => {
          const forkliftIdx = state.findIndex(item => item._id === forklift._id);
          state.splice(forkliftIdx, 1, forklift);
        });

        return [...state];
      }

      case CONST.DELETE_FORKLIFT_SUCCESS:
        return state.filter(forklift => forklift._id !== action.payload.forkliftId);

      default:
        return state;
    }
  },
  forkliftsMeta: (state = null, action) => {
    switch (action.type) {
      case CONST.GET_FORKLIFTS_SUCCESS:
        return action.payload.meta;
      case CONST.ADD_FORKLIFT_SUCCESS:
        if (!state) {
          return null;
        }
        return {
          ...state,
          available: state.available + 1,
          all: state.all + 1,
        };
      case CONST.DELETE_FORKLIFT_SUCCESS:
        if (!state) {
          return null;
        }
        return {
          ...state,
          available: state.available - 1,
          all: state.all - 1,
        };
      default:
        return state;
    }
  },
  forkliftInternalId: (state = null, action) => {
    switch (action.type) {
      case CONST.GET_FORKLIFT_INTERNAL_ID_REQUEST:
        return null;

      case CONST.GET_FORKLIFT_INTERNAL_ID_SUCCESS:
        return action.payload.forkliftInternalId;

      default:
        return state;
    }
  },
});
