import React, { FC } from 'react';
import dayjs from 'dayjs';
import { Flex, List, Tabs } from 'antd';
import { CurrencyType, OrderModel } from '../../reducers/orders/model';
import { ListItemSC, ListItemMetaSC } from './styles';
import { QuantityAndPrice } from './QuantityAndPrice';

type OrdersClientTabProps = {
  orders: OrderModel[];
};

export const OrdersClientTab: FC<OrdersClientTabProps> = ({ orders }) => {
  const ordersByYear = orders.reduce<Record<string, OrderModel[]>>((acc, order) => {
    const year = dayjs(order.createdAt).year();
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(order);

    return acc;
  }, {});

  const ordersYears = Object.keys(ordersByYear).sort((a, b) => parseInt(b) - parseInt(a));

  const totalPricesByYear = ordersYears.reduce<
    Record<string, { sumPLN: number; sumEUR: number; currency: string; productsQuantity: number }>
  >((acc, year) => {
    acc[year] = ordersByYear[year].reduce(
      (sums, order) => {
        if (order.currency === CurrencyType.EUR) {
          sums.sumEUR += order.price;
        } else {
          sums.sumPLN += order.price;
        }
        sums.currency = order.currency;
        sums.productsQuantity += order.productsInOrder.length;
        return sums;
      },
      { sumPLN: 0, sumEUR: 0, currency: CurrencyType.PLN, productsQuantity: 0 },
    );

    return acc;
  }, {});

  return (
    <Tabs
      defaultActiveKey='0'
      items={ordersYears.map((year, index) => ({
        key: index.toString(),
        label: year,
        children: (
          <>
            <Flex
              justify='flex-end'
              gap='small'
            >
              {totalPricesByYear[year].sumPLN > 0 && (
                <QuantityAndPrice
                  currency={CurrencyType.PLN}
                  price={totalPricesByYear[year].sumPLN}
                  quantity={totalPricesByYear[year].productsQuantity}
                  colorText='black'
                />
              )}
              {totalPricesByYear[year].sumEUR > 0 && (
                <QuantityAndPrice
                  currency={CurrencyType.EUR}
                  price={totalPricesByYear[year].sumEUR}
                  quantity={totalPricesByYear[year].productsQuantity}
                  colorText='black'
                />
              )}
            </Flex>
            <List>
              {ordersByYear[year].map(order => (
                <ListItemSC key={order._id}>
                  <ListItemMetaSC
                    title={`Zamówienie: ${order.orderNr}`}
                    description={
                      <Flex gap='small'>
                        <QuantityAndPrice
                          currency={order.currency}
                          price={order.price}
                          quantity={order.productsInOrder.length}
                        />
                      </Flex>
                    }
                  />
                </ListItemSC>
              ))}
            </List>
          </>
        ),
      }))}
    />
  );
};
