import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { notification } from 'antd';

import { addReservationError, addReservationRequest, addReservationSuccess } from './actions';

import * as CONST from './consts';
import { ReservationModel } from './model';
import { ResponseModel } from '../model';
import { getErrorMessage } from '../../utils/error';
import { ActionType } from 'typesafe-actions';
import { getAvailableForkliftsRequest } from '../forklift/actions';
import { getEquipmentsRequest } from '../equipment/actions';
import { apiClientReservations } from '../../services/apiClient/reservations';

function* addReservation(
  action: ActionType<typeof addReservationRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<ReservationModel>> {
  try {
    const { reservation, onSuccess } = action.payload;
    const response = yield call(apiClientReservations.addReservation, reservation);

    yield put(addReservationSuccess(response.data));
    yield put(getAvailableForkliftsRequest());
    yield put(getEquipmentsRequest());
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding reservation',
      description: getErrorMessage(err),
    });
    yield put(addReservationError(err));
  }
}

export function* watchReservationsSaga(): Generator {
  yield takeLatest(CONST.ADD_RESERVATION_REQUEST, addReservation);
}
