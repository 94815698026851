import React, { FC } from 'react';
import { CurrencyType, CurrencyTypeNames } from '../../reducers/orders/model';
import { TextSC } from './styles';

type QuantityAndPriceProps = {
  quantity: number;
  price: number;
  currency: CurrencyType;
  colorText?: string | null;
};

export const QuantityAndPrice: FC<QuantityAndPriceProps> = ({ quantity, price, currency, colorText }) => {
  return (
    <>
      <TextSC
        strong
        type='secondary'
        className={colorText ? colorText : ''}
      >
        Ilość:
      </TextSC>{' '}
      <TextSC
        type='secondary'
        className={colorText ? colorText : ''}
      >
        {quantity}
      </TextSC>
      <TextSC
        strong
        type='secondary'
        className={colorText ? colorText : ''}
      >
        Kwota:
      </TextSC>{' '}
      <TextSC
        type='secondary'
        className={colorText ? colorText : ''}
      >
        {price} {CurrencyTypeNames[currency]}
      </TextSC>
    </>
  );
};
