import React, { FC } from 'react';
import { Col, Divider, List, Row, Typography } from 'antd';
import { gutterInner } from '../../forms/forklift';
import { ClientModel, ClientTypeNames } from '../../reducers/clients/model';
import { countriesMap } from '../../utils/countries/countries';
import { CurrencyType, CurrencyTypeNames } from '../../reducers/orders/model';

const { Text } = Typography;

import { OrdersClientTab } from './OrdersClientTab';

type OrderSum = {
  sumPLN: number;
  sumEUR: number;
};

type ForkliftProps = {
  client: ClientModel | undefined;
};

export const Client: FC<ForkliftProps> = props => {
  const { client } = props;

  const sumOrder = client?.orders.reduce<OrderSum>(
    (sum, order) => {
      if (order.currency === CurrencyType.EUR) {
        sum.sumEUR += order.price;
        return sum;
      }

      sum.sumPLN += order.price;
      return sum;
    },
    { sumPLN: 0, sumEUR: 0 },
  );

  return (
    <>
      <Row gutter={gutterInner}>
        <Col span={12}>
          <List
            className='quickViewList'
            size='small'
          >
            <List.Item
              className='quickViewListElement'
              style={{ paddingTop: 0 }}
            >
              <List.Item.Meta
                title='Pełna nazwa firmy:'
                description={client?.companyName}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Typ klienta:'
                description={client && ClientTypeNames[client.clientType]}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Osoba kontaktowa:'
                description={`${client?.contactName ?? ''} ${client?.contactSurname ?? ''}`}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='E-mail osoby kontaktowej:'
                description={client?.contactEmail}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Telefon osoby kontaktowej:'
                description={client?.contactPhone}
              />
            </List.Item>
          </List>
        </Col>
        <Col span={12}>
          <List
            className='quickViewList'
            size='small'
          >
            <List.Item
              className='quickViewListElement'
              style={{ paddingTop: 0 }}
            >
              <List.Item.Meta
                title='NIP:'
                description={client?.companyNIP}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Ulica:'
                description={client?.companyStreet}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Kod pocztowy:'
                description={client?.companyPostCode}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Miejscowość:'
                description={client?.companyCity}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Kraj:'
                description={client && countriesMap[client.companyCountry].name}
              />
            </List.Item>
          </List>
        </Col>
      </Row>
      <Divider />
      {sumOrder && sumOrder.sumPLN > 0 && (
        <Row justify='end'>
          <Col>
            <Text strong>
              Razem: {sumOrder.sumPLN} {CurrencyTypeNames[CurrencyType.PLN]}
            </Text>
          </Col>
        </Row>
      )}
      {sumOrder && sumOrder.sumEUR > 0 && (
        <Row justify='end'>
          <Col>
            <Text strong>
              Razem: {sumOrder.sumEUR} {CurrencyTypeNames[CurrencyType.EUR]}
            </Text>
          </Col>
        </Row>
      )}
      {client?.orders && <OrdersClientTab orders={client.orders} />}
    </>
  );
};
