import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Dropdown, Row } from 'antd';

import { HeaderComponent } from '../../component/header/HeaderComponent';
import { useViewport } from '../../utils/customHooks/useViewport';
import { HeaderComponentMobile } from '../../component/header/HeaderComponentMobile';
import { PartsAdditionalMenu } from './PartsAdditionalMenu';
import { PartModel } from '../../reducers/parts/model';
import { itemsStatuses } from '../../utils/itemsStatus';
import { isProductStatus } from '../../utils/productsStatus';
import { ProductStatusListType } from '../../reducers/forklift/model';

type PartsHeaderProps = {
  onSetOpenPartModal: (openPartModal: boolean) => void;
  selectedParts: PartModel[];
  groupEditParts: string[];
  setOpenOrderModal: (openOrderModal: boolean) => void;
};

export const PartsHeader: FC<PartsHeaderProps> = props => {
  const { onSetOpenPartModal, selectedParts, groupEditParts, setOpenOrderModal } = props;
  const { isMobile } = useViewport();
  const dispatch = useDispatch();

  const editItems = (selectedParts: PartModel[]) => {
    const itemsStatus = itemsStatuses({ dispatch, partIds: groupEditParts });
    itemsStatus.push({
      label: 'Zamówienie',
      key: 'order',
      onClick: () => {
        setOpenOrderModal(true);
      },
      disabled: !selectedParts.every(part => isProductStatus(part, ProductStatusListType.READY_FOR_ORDER)),
    });

    return itemsStatus;
  };

  return isMobile ? (
    <HeaderComponentMobile
      title='Części'
      onAddElementOpenModal={onSetOpenPartModal}
    >
      <PartsAdditionalMenu />
    </HeaderComponentMobile>
  ) : (
    <HeaderComponent title='Części'>
      <Row gutter={16}>
        <Col>
          <Button
            type='primary'
            onClick={() => onSetOpenPartModal(true)}
          >
            Dodaj części
          </Button>
        </Col>
        <Col>
          <Dropdown
            menu={{ items: editItems(selectedParts) }}
            arrow
            disabled={groupEditParts.length <= 0}
          >
            <Button disabled={groupEditParts.length <= 0}>Edytuj</Button>
          </Dropdown>
        </Col>
        <PartsAdditionalMenu />
      </Row>
    </HeaderComponent>
  );
};
